import React, { useState } from "react";
import { SendBirdProvider as SBProvider } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";

//import { USER_ID,APP_ID,NICKNAME,PROFILEIMAGE} from "./const";
import CustomizedApp from "./CustomizedApp";
import "./index.css";
const ltxv=document.getElementById('ltx_chat_root');
const NICKNAME=ltxv.getAttribute("data-fullname");
const PROFILEIMAGE=ltxv.getAttribute("data-profile_img");
const APP_ID=ltxv.getAttribute("data-apiid");
const USER_ID=ltxv.getAttribute("data-USER_ID");
export default function App() {
  return (
    <div className="app-wrapper">

      <SBProvider
      userId={USER_ID} 
      appId={APP_ID} 
      nickname={NICKNAME}
      profileUrl={PROFILEIMAGE} 
      >
        <CustomizedApp currentUser={USER_ID} />
      </SBProvider>
    </div>
  );
}
