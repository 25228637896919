import React, { useState,useRef, useEffect } from "react";
import $ from 'jquery';
import {
  OpenChannel,
  withSendBird
} from "sendbird-uikit";
import { timestampToTime } from './messageUtils'


var axios = require('axios');

const UserProfile = ({ user }) => {
  
  var userMeta = user.nickname;
  let getsplit=['-','#'];
  if(userMeta){
    getsplit=userMeta.split('|');
    
  }
  if (user.plainProfileUrl) {
      return <div className="user-profile">
            <a href={getsplit[1]} target="_blank">
              <h5>{getsplit[0]}</h5>
            <img className="profile-image" src={user.plainProfileUrl} />
            </a>
        </div>
  } else {
      return <div className="user-profile">
         <a href={getsplit[1]} target="_blank">
              <h5>{getsplit[0]}</h5>
            <img className="profile-image" src={user.plainProfileUrl} />
            </a>
        </div>
    

  }
}


function CustomizedApp(props) {

  
  // props
  const {
    config: { userId }
  } = props;

  // useState
  const [currentChannelUrl, setCurrentChannelUrl] = useState("");


  if(!currentChannelUrl){
    setCurrentChannelUrl('sendbird_open_channel_112939_55ceb35beea619f307e6d518b4b83f6e8cc6afaa');
  }


  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
       
        event.preventDefault();
        $('.sendbird-conversation__scroll-container').animate({
	    		scrollTop:100000000}, "slow");
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  
   console.log('pppppppp',currentChannelUrl);
  
  return (
    <div className="customized-app">
      <div className="sendbird-app__wrap">
        
        <div className="sendbird-app__conversation-wrap">
          {(
            <OpenChannel
              channelUrl={currentChannelUrl}
              renderCustomMessage={(message, channel) => {
            
                if(message.sender){
               let x= message.sender.userId;
               let user= message.sender;

                if(x==props.currentUser){
                  return () => (
                    <div className="user-chat currentUser" >
                        <UserProfile user={user} />
                        <span className="msg-content">{message.message}</span>
                        <div>{timestampToTime(message.createdAt)}</div>
                    </div>
                  );
                }else{
                  return () => (
                    <div className="user-chat otherUser" >
                         <UserProfile user={user} />
                         <span className="msg-content">{message.message}</span> 
                         <div>{timestampToTime(message.createdAt)}</div>
                    </div>
                  );
                }
              }else{
               return ()=>(
                 <p></p>
               );
            }
                // or
                // if (message.customType === 'type') { //  render function component }
              }}
            />
          )}
        </div>
      
      </div>
    </div>
  );
}

export default withSendBird(CustomizedApp);
